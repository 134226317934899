import App from '@pages/TopN/TopN.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'
import { createApp } from 'vue'

import { registerPlugins } from '@plugins/mapRouter'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import topNRoute from '@pages/TopN/TopNRoute'
import 'virtual:svg-icons-register'


const app = createApp(App)
const router = topNRoute
const pinia = createPinia()
pinia.use(createPersistedState())

registerPlugins(app, router, pinia)

app.mount('#app')
